<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 lg8 pt-6>
      <v-layout wrap>
        <v-flex xs12>
          <v-card
            class="mx-auto elevation-1 pa-6"
            max-width="1088px"
            align="center"
            outlined
          >
            <v-layout wrap>
              <v-flex
                xs12 sm7
                lg7
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
                >Item List
              </v-flex>
              <v-flex xs12 sm3 lg3 align-self-center class="text-left nsregular">
                <v-text-field
                  v-model="keyword"
                  dense
                  class="rounded-xl"
                  label="Search"
                  outlined
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 lg2 pt-2 pt-sm-0>
                <!-- <v-card rounded outlined  style="cursor: pointer" color="transparent" @click="adddialogue = true"> -->
                <v-btn
                  block rounded
                  width="100px"
                  dark
                  color="#766BC0"
                  class="py-4 hidden-sm-and-up"
                  @click="adddialogue = true"
                >
                  <v-icon x-small>mdi-plus</v-icon>
                  <span
                    class="text-left nsregular"
                    style="font-weight: 400; font-size: 12px"
                    >Add</span
                  ></v-btn
                >
                <v-btn
                  x-small
                  width="100px"
                  dark
                  color="#766BC0"
                  class="py-4 hidden-xs-only"
                  @click="adddialogue = true"
                >
                  <v-icon x-small>mdi-plus</v-icon>
                  <span
                    class="text-left nsregular"
                    style="font-weight: 400; font-size: 12px"
                    >Add</span
                  ></v-btn
                >
                <!-- </v-card> -->
              </v-flex>
            </v-layout>
            <v-layout py-3>
              <v-flex
                xs1
                class="text-left nsbold"
                style="font-weight: 700; font-size: 18px"
              >
                <span>No.</span>
              </v-flex>
              <v-flex
                xs9
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Name</span>
              </v-flex>
            </v-layout>
            <v-layout
              v-for="(item, i) in student"
              :key="i"
              py-2
              :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
            >
              <v-flex
                xs1
                class="text-left regular"
                style="font-weight: 400; font-size: 18px"
              >
                <!-- <span>{{ i + 1 }}</span> -->
                <span v-if="page == 1">
                  {{ i + 1 }}
                </span>
                <span v-else>
                  {{ i + 1 + 20 * (page - 1) }}
                </span>
              </v-flex>
              <v-flex
                xs9
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.name }}</span>
              </v-flex>
              <v-flex xs1 v-if="item">
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(curItem = item), (editdialogue = true)"
                    >mdi-circle-edit-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-flex xs1>
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(dialoge = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-divider></v-divider>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm Delete</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4 black--text"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="400px" v-model="editdialogue">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Edit Item </v-toolbar-title>
          </v-toolbar>

          <v-layout wrap v-if="curItem">
            <v-flex xs12 pa-4>
              <v-text-field
                v-model="curItem.name"
                outlined
                dense
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="editdialogue = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="editCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="400px" v-model="adddialogue">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Add Item </v-toolbar-title>
          </v-toolbar>

          <v-layout wrap>
            <v-flex xs12 pa-4>
              <v-text-field
                autofocus
                v-model="name"
                outlined
                dense
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="adddialogue = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="addCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-snackbar
        :timeout="2000"
        absolute
        top
        color="blue"
        right
        text
        v-model="showsnackbar"
      >
        {{ msg }}
      </v-snackbar> -->
      <v-layout wrap>
        <v-flex xs12 pt-4>
          <v-pagination
            small
            color="#766BC0"
            v-model="page"
            :length="Pagelength"
          >
          </v-pagination>
        </v-flex>
      </v-layout>
      <v-snackbar
        :timeout="3000"
        :value="true"
        absolute
        centered
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      student: [],
      dialoge: false,
      editdialogue: false,
      adddialogue: false,
      name: null,
      curId: null,
      curItem: null,
      showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      keyword: null,
      page: 1,
      Pagelength: 0,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    keyword() {
      this.getData();
    },
    page() {
      // this.getData();
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/cloth/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          keyword: this.keyword,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.student = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/remove/clothname",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //add button
    addCategory() {
      axios({
        url: "/cloth/name/add",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          name: this.name,
        },
      })
        .then((response) => {
          this.adddialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }

          this.name = null;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //edit button
    editCategory() {
      axios({
        url: "/cloth/name/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          name: this.curItem.name,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>